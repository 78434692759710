/*
 * TODO: Fix content menu
 */

const PromptLayout = ({ children }) => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="mb-5 bg-white col-md-6 py-3">
          <div className="card-body">{children}</div>
        </div>
      </div>
    </div>
  );
};


export {  PromptLayout };