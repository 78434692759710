import { SkuOptionModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/SkuOptions/SkuOptions.componentModel";
import { OptionGroup, Option } from "@ultracommerce/react-storefront/global/src/interface/Product";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useTranslation } from "react-i18next";
import { OptionButtons } from "../../Controls/OptionButtons"

export function SkuOptionsComponentView({
  componentData,
  updateFilters,
  resetFilters,
}: {
  componentData: SkuOptionModel;
  updateFilters: (optionGroup: OptionGroup, option: Option) => void;
  resetFilters: () => void;
}) {
  const { ProductModule } = useElementContext();
  const { t } = useTranslation();
  console.log(componentData);
  if (componentData?.optionGroups?.some(({ options }) => options.length >= 2)) {
    if (componentData.optionStyle === "tile") {
      return (
        <>
        <h5>{t("frontend.chooseAnOption")}</h5>
        <OptionButtons
          productOptions={componentData.filteredOptions}
          updateFilters={updateFilters}
        />
        </>
      );
    }
    return (
      <ProductModule.CoreControl.OptionDropdown
        optionData={componentData.filteredOptions}
        updateFilters={updateFilters}
        resetFilters={resetFilters}
      />
    );
  } else {
    return <></>;
  }
}
