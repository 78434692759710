import { useTranslation } from "react-i18next";
import { Sku } from "@ultracommerce/react-storefront/global/src/interface/Product";
import { Quote } from "@ultracommerce/react-storefront/global/src/interface/Quote";
import { List } from "@ultracommerce/react-storefront/global/src/interface/List";
import { useElementContext } from "@ultracommerce/react-storefront/global";

export interface ProductMultiCartButtonsProps {
  sku: Sku;
  quotes: Quote[];
  wishlists: List;
  isLoading: boolean;
  setQuoteModal: (isOpen: boolean) => void;
  setListModal: (isOpen: boolean) => void;
  addToCartOrQuote: (quote?: Quote) => void;
  showExistingQuoteAndLists: boolean;
}

const ProductMultiCartButtons = ({
  sku,
  wishlists,
  isLoading,
  addToCartOrQuote,
}: ProductMultiCartButtonsProps) => {
  const { t } = useTranslation();
  const {
    CommonModule: { Button },
  } = useElementContext();
  return (
    <div className="accordion accordion-cart" id="accordioncartExample">
      <div className="accordion-item quote-input-box d-flex">
        {/* Add to cart button */}
        <Button
          classList="btn btn-primary flex-grow-1"
          isLoading={isLoading}
          disabled={!sku.settings.skuAllowAddToCartFlag || (!sku?.salePrice && !sku?.listPrice)}
          onClick={(e) => {
            e.preventDefault();
            addToCartOrQuote();
          }}
        >
          {t("frontend.product.add_to_cart")}
        </Button>

        

       
      </div>
    </div>
  );
};

export { ProductMultiCartButtons };
