import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  CommonModule,
  ComponentContextProvider,
  ElementContextProvider,
  ServiceContextProvider,
  AppSwitcher,
  ProductModule,
  PageModule,
  initApp,
} from "@ultracommerce/react-storefront/global";

import "./i18n";
import store from "./createStore";
import "./assets/theme";
import preload from "./preload";
import {ProductMultiCartButtons} from './modules/Product/Controls/ProductMultiCartButtons'
import { PromptLayout } from "./components/Account/AccountLayout/AccountLayout";
import { OrderDetails } from "./components/Account/AccountOrderDetail/OrderDetails";
import { CartOrderSummary } from "./components/Checkout/OrderSummary";
import ProductDetailsPage from "./pages/ProductDetailsPage/ProductDetailsPage.page";
import Checkout from "./pages/Checkout/Checkout";

ProductModule.CoreControl.ProductMultiCartButtons = ProductMultiCartButtons
initApp(preload);

PageModule.ProductDetailsPage = ProductDetailsPage;
PageModule.Checkout = Checkout;
CommonModule.PromptLayout = PromptLayout;
CommonModule.OrderDetails = OrderDetails;
CommonModule.CartOrderSummary = CartOrderSummary;

createRoot(document.getElementById("app")!).render(
  <Provider store={store}>
    <Router>
      <ServiceContextProvider>
        <ElementContextProvider>
          <ComponentContextProvider customComponents={{ProductModule,CommonModule}}>
            <AppSwitcher />
          </ComponentContextProvider>
        </ElementContextProvider>
      </ServiceContextProvider>
    </Router>
  </Provider>
);
