import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes as RouterRoutes, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import {
  clearUser,
  receiveUser,
  requestLogOut,
  receiveCart,
  requestCart,
  useServiceContext,
  useElementContext,
  SlatwalApiService,
  getErrorMessage,
  isAuthenticated,
  PaymentContextProvider,
} from "@ultracommerce/react-storefront/global";
import "./checkout.css";
import { SHIPPING, getCurrentStep } from "../../components/Checkout/steps";
import { CheckoutSideBar } from "../../components/Checkout/CheckoutSideBar";
import { STRIPE_REDIRECT_PATH } from "@ultracommerce/react-storefront/global/src/components/Checkout/Payment/StripePayment";
import { ShippingSlide } from "../../components/Checkout/Shipping/Shipping"
import { PaymentSlide } from "../../components/Checkout/Payment/Payment"

const Routes = Sentry.withSentryReactRouterV6Routing(RouterRoutes);

const Checkout = () => {
  const {
    PageModule: { DynamicPage },
    CommonModule: {
      AccountLogin,
      CreateGuestAccount,
      ThreeDSRedirect,
      RedirectWithReplace,
      StripeCompletePage,
      Spinner,
    },
  } = useElementContext();
  const { LogService } = useServiceContext();
  const logService = useMemo(() => new LogService(), [LogService]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const path = pathname.split("/").reverse()?.at(0).toLowerCase();
  const currentStep = getCurrentStep(path);
  const {
    verifiedAccountFlag,
    isFetching,
    accountID,
    calculatedGuestAccountFlag = false,
  } = useSelector((state) => state.userReducer);
  const enforceVerifiedAccountFlag = useSelector(
    (state) => state.configuration.enforceVerifiedAccountFlag
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cartState = useSelector((state) => state.cart); // check if there is some change in state , just to run use effect
  const [threeDSRedirect, setThreeDSRedirect] = useState();
  const [orderProperties, setOrderProperties] = useState({
    OrderDeliveryInstructions: cartState.deliveryInstructions,
    customerReferenceNumber: cartState.customerReferenceNumber,
    orderNotes: cartState.orderNotes,
  });

  useEffect(() => {
    if (currentStep.key === SHIPPING && !!cartState.orderID) {
      logService.beginCheckout(cartState.orderItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, logService, cartState.orderID]);

  const placeOrder = (event) => {
    event?.preventDefault();
    dispatch(requestCart());
    // Store the hash for guest Checkout
    const payload = !!calculatedGuestAccountFlag ? "cart,account" : "cart";
    const { orderNotes, OrderDeliveryInstructions, customerReferenceNumber } =
      orderProperties;
    return SlatwalApiService.cart
      .placeOrder({
        returnJSONObjects: payload,
        transactionInitiator: "ACCOUNT",
        orderNotes,
        OrderDeliveryInstructions,
        customerReferenceNumber,
      })
      .then((response) => {
        if (response.isSuccess()) {
          const placeOrderResp = response.success();
          const orderHasError =
            Object.keys(placeOrderResp?.errors || {})?.length > 0;
          if (placeOrderResp?.redirectUrl) {
            const { redirectUrl, redirectPayload, redirectMethod } =
              placeOrderResp;
            setThreeDSRedirect({
              redirectUrl,
              redirectPayload,
              redirectMethod,
            });
            return true;
          } else {
            if (orderHasError) {
              toast.error(getErrorMessage(response.success().errors));
              return false;
            } else {
              dispatch(receiveCart(placeOrderResp.cartState));
              dispatch(receiveUser(placeOrderResp.account));
              // TODO: verify isGuest
              if (!!calculatedGuestAccountFlag) {
                navigate(
                  `/my-account/order-detail?token=${cartState.orderID}:${accountID}`
                );
              } else {
                navigate("/order-confirmation");
              }
              logService.purchase(cartState);
              return true;
            }
          }
        } else {
          toast.error(t("frontend.core.error.network"));
          dispatch(receiveCart());
          return false;
        }
      })
      .catch((err) => {
        toast.error(t("frontend.core.error.network"));
        dispatch(receiveCart());
        return false;
      });
  };

  const getDisabledStatus = (key) => {
    switch (key) {
      case "shipping":
        return !cartState?.orderItems.length;
      case "payment":
        return !cartState?.orderFulfillments?.at(0)?.shippingAddress?.addressID;
      default:
        return true;
    }
  };

  const tabs = [
    { title: "Shipping", key: "shipping", content: <ShippingSlide /> },
    { title: "Payment & Billing", key: "payment", content: <PaymentSlide cartState={cartState} placeOrder={placeOrder} /> },
  ];
  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(clearUser());
      dispatch(requestLogOut());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    enforceVerifiedAccountFlag &&
    !verifiedAccountFlag &&
    isAuthenticated() &&
    !isFetching &&
    accountID.length > 0
  )
    return <Navigate to="/account-verification" />;

  if (!cartState || (cartState.isFetching && !cartState.orderID))
    return (
      <DynamicPage>
        <Spinner />
      </DynamicPage>
    );
  if (pathname === STRIPE_REDIRECT_PATH) {
    return (
      <DynamicPage>
        <PaymentContextProvider orderID={cartState.orderID}>
          <StripeCompletePage placeOrder={placeOrder} />
        </PaymentContextProvider>
      </DynamicPage>
    );
  }

  if (cartState?.orderItems.length === 0) {
    return (
      <div className="container my-5">
        <div className="text-center p-3">
          <h3>{t("frontend.cart.empty_cart")}</h3>
        </div>
      </div>
    );
  }

  return (
    <DynamicPage ignoreLayout={true}>
      <div className="checkout container pb-5 mb-2 mb-md-4 pt-4">
        {!isAuthenticated() && (
          <div className="row">
            <section className="col">
              {/* <!-- Steps--> */}
              <Routes>
                <Route
                  path={`createGuestAccount`}
                  element={<CreateGuestAccount />}
                />
                <Route
                  path={`createGuestAccount`}
                  element={<CreateGuestAccount />}
                />
                <Route
                  path={`cart`}
                  element={
                    <RedirectWithReplace pathname={`../../shopping-cart`} />
                  }
                />
                <Route
                  path={`*`}
                  element={<AccountLogin isCheckout={true} />}
                />
                {/* <Route path={`*`} element={<AccountLogin isCheckout={true} />} /> */}
              </Routes>
            </section>
            {/* <!-- Sidebar--> */}
          </div>
        )}
        {isAuthenticated() && (
          <PaymentContextProvider orderID={cartState.orderID}>
            <div className="row">
              <section className="col-lg-8">
                {/* <!-- Steps--> */}
                <Routes>
                  <Route
                    path={`cart`}
                    element={
                      <RedirectWithReplace pathname={`../../shopping-cart`} />
                    }
                  />
                </Routes>
                <div className="accordion-section">
                  <div className="accordion border" id="accordionExample">
                    {tabs.map(
                      (tab, index) =>
                        tab?.content && (
                          <div className={`accordion-item-${index}`} key={index}>
                            <h2
                              className="accordion-header border-bottom pt-0"
                              id="headingOne"
                            >
                              <button
                                className="accordion-button text-uppercase"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseOne-${index}`}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                disabled={getDisabledStatus(tab.key)}
                                id={`accordion-${tab.key}`}
                              >
                                {tab.title}
                              </button>
                            </h2>
                            <div
                              id={`collapseOne-${index}`}
                              className={`accordion-collapse collapse ${
                                index === 0 ? "show" : ""
                              }`}
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {tab.content}
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </section>
              {/* <!-- Sidebar--> */}
              <CheckoutSideBar
                placeOrder={placeOrder}
                orderProperties={orderProperties}
                setOrderProperties={setOrderProperties}
              />
              {threeDSRedirect && (
                <ThreeDSRedirect
                  url={threeDSRedirect.redirectUrl}
                  payload={threeDSRedirect.redirectPayload}
                  method={threeDSRedirect.redirectMethod}
                />
              )}
            </div>
          </PaymentContextProvider>
        )}
      </div>
    </DynamicPage>
  );
};

export default Checkout;