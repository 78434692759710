import { useDispatch, useSelector } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getPaymentIntegrations, getSiteConfig, receiveCart, axios, getSdkURL,useElementContext, EXTERNAL_PAYMENT_CODE, PAYPAL_COMMERCE_CODE } from "@ultracommerce/react-storefront/global";

export const isPaypalCommercePayment = (payment) => {
  return (
    payment?.paymentMethodType === EXTERNAL_PAYMENT_CODE &&
    payment?.paymentIntegration?.integrationPackage === PAYPAL_COMMERCE_CODE
  );
};

const PayPalCommercePayment = ({ method, cartState }) => {
  const {
    CommonModule: { Overlay },
  } = useElementContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currencyCode } = useSelector(getSiteConfig);
  const { settings } = useSelector(getSiteConfig);
  const { firstName, lastName } = useSelector((state) => state.userReducer);
  const integrations = useSelector(getPaymentIntegrations);
  const paypalIntegration = integrations.filter((integration) => integration.key.toLowerCase() === "paypalcommerce");
  const [isLoading, setLoading] = useState(false);
  if (!paypalIntegration.length) return null;

  var options = {
    "client-id": paypalIntegration[0]?.settings?.clientid,
    intent: "authorize",
    currency: currencyCode,
    components: "funding-eligibility,buttons",
    "enable-funding": paypalIntegration[0]?.settings?.payoptions,
    "merchant-id": paypalIntegration[0]?.settings?.partnerid,
    "data-partner-attribution-id": paypalIntegration[0]?.settings?.bncode,
    ...(paypalIntegration[0]?.settings.locale?.trim() ? { locale: paypalIntegration[0]?.settings.locale.trim() } : {}),
  };

  if (!paypalIntegration?.at(0)?.settings?.livemode) {
    var buyerCountry = {
      "buyer-country": settings.siteDefaultCountry,
    };
    options = { ...options, ...buyerCountry };
  }

  var orderItems = [];
  if (cartState && Array.isArray(cartState.orderItems)) {
    for (let orderItem of cartState.orderItems) {
      var itemDetails = {
        name: orderItem.sku?.product?.productName || "Unknown Product",
        quantity: orderItem.quantity || 1,
        unit_amount: {
          currency_code: currencyCode,
          value: orderItem.price || 0,
        },
        sku: orderItem.sku?.skuCode || "N/A",
      };
      orderItems.push(itemDetails);
    }
  } else {
    console.warn("cartState or orderItems is undefined or not an array.");
  }
  return (
    <>
      <Overlay active={isLoading} spinner>
        <PayPalScriptProvider options={options}>
          <PayPalButtons
            style={{ color: "gold", layout: "vertical", height: 48, shape: "pill", width: 30 }}
            createOrder={(data, actions) => {
              var shippingDetail = {};
              if (cartState.orderFulfillments?.at(0)?.fulfillmentMethod?.fulfillmentMethodType === "pickup") {
                shippingDetail["type"] = "PICKUP_IN_PERSON";
                shippingDetail["name"] = {
                  full_name: firstName + " " + lastName,
                };
                const shippingAddress = cartState.orderFulfillments?.at(0)?.pickupLocation.primaryAddress.address;
                shippingDetail["address"] = shippingAddress.countrycode
                  ? {
                      address_line_1: shippingAddress.streetAddress,
                      address_line_2: shippingAddress.street2Address,
                      postal_code: shippingAddress.postalCode,
                      country_code: shippingAddress.countrycode,
                      admin_area_1: shippingAddress.stateCode,
                      admin_area_2: shippingAddress.city,
                    }
                  : undefined;
              } else {
                const shippingAddress = cartState.orderFulfillments?.at(0)?.shippingAddress;
                shippingDetail["type"] = "SHIPPING";
                shippingDetail["name"] = {
                  full_name: shippingAddress.name,
                };
                shippingDetail["address"] = shippingAddress.countrycode
                  ? {
                      address_line_1: shippingAddress.streetAddress,
                      address_line_2: shippingAddress.street2Address,
                      postal_code: shippingAddress.postalCode,
                      country_code: shippingAddress.countrycode,
                      admin_area_1: shippingAddress.stateCode,
                      admin_area_2: shippingAddress.city,
                    }
                  : undefined;
              }

              return actions.order
                .create({
                  intent: "AUTHORIZE",
                  purchase_units: [
                    {
                      amount: {
                        currency_code: currencyCode,
                        value: cartState.total,
                        breakdown: {
                          item_total: {
                            currency_code: currencyCode,
                            value: cartState.subtotal,
                          },
                          tax_total: {
                            currency_code: currencyCode,
                            value: cartState.taxTotal,
                          },
                          discount: {
                            currency_code: currencyCode,
                            value: cartState.discountTotal,
                          },
                          shipping: {
                            currency_code: currencyCode,
                            value: cartState.fulfillmentTotal,
                          },
                        },
                      },
                      payee: {
                        merchant_id: paypalIntegration?.at(0)?.settings?.partnerID,
                      },
                      items: orderItems,
                      shipping: shippingDetail,
                    },
                  ],
                  payment_source: {
                    paypal: {
                      experience_context: {
                        shipping_preference: shippingDetail.address ? "SET_PROVIDED_ADDRESS" : "GET_FROM_FILE",
                      },
                    },
                  },
                })
                .then((orderID) => {
                  return orderID;
                })
                .catch((e) => {
                  toast.error("Error Occured while creating an Order on PayPal. Please Contact Admin.");
                  console.error(e);
                });
            }}
            onApprove={(data, actions) => {
              setLoading(true);
              actions.order.authorize().then(function (authorization) {
                // Get the authorization id
                var authorizationID = authorization.purchase_units?.at(0).payments.authorizations?.at(0).id;

                return axios(`${getSdkURL()}api/scope/initiatePayment`, {
                  method: "post",
                  withCredentials: true,
                  headers: {
                    "content-type": "application/json",
                  },
                  data: {
                    paypalOrder: data.orderID,
                    paypalAuthorization: authorizationID,
                    returnJSONObjects: "cart",
                  },
                })
                  .then((response) => {
                    if (response?.status === 200) {
                      setLoading(false);
                      dispatch(receiveCart(response.data.cart));
                    } else {
                      setLoading(false);
                      toast.error(t("frontend.account.forgot.failure"));
                    }
                    return response;
                  })
                  .catch((err) => {
                    setLoading(false);
                    toast.error(t("frontend.account.forgot.failure"));
                  });
              });
            }}
            onCancel={() => {
              // On Cancelling the PayPal Popup, it redirects to the payment methods listing page.
            }}
          />
        </PayPalScriptProvider>
      </Overlay>
    </>
  );
};
export { PayPalCommercePayment };
