import View from "./ProductDetailsPage.pageView";

import { useComponentModel } from "@ultracommerce/react-storefront/global/src/pages/ProductDetailsPage/ProductDetailsPage.pageService";
import {
  ProductContextProvider,
  useServiceContext,
} from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useEffect } from "react";

const ProductDetailPage = () => {
  const {
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow },
  } = useElementContext();
  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <ProductContextProvider>
          <PageContentRow>
            <ProductDetailPageView />
          </PageContentRow>
          {renderDynamicContent()}
        </ProductContextProvider>
      )}
    </DynamicPage>
  );
};

const ProductDetailPageView = () => {
  const { componentData } = useComponentModel();

  const { LogService } = useServiceContext();
  useEffect(() => {
    new LogService().viewItem([componentData.product]);
  }, [LogService, componentData.product]);

  // Add config override for product detail here
  return <View {...componentData} />;
};

export default ProductDetailPage;
